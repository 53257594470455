import React, { useState, useRef, useEffect } from 'react';
import { message, List, Button } from 'antd';

import customConfig from '../../custom_config';

// 滚动加载组件
import InfiniteScroll from 'react-infinite-scroller';
import PurchaseRccordEdit from './purchase_record_edit';
import { GetRecordsRequest } from './records_request_handle';
import ImageZoom from '../base_components/image_zoom';

// react-infinite-scroller install: npm install react-infinite-scroller --save

import './purchase_records.css';

const purchaseStates = customConfig.purchaseStates;
const salePlatforms = customConfig.salePlatforms;

const PurchaseRecords = ({ queryFactor }) => {
  // 顾客购物单列表
  const [records, setRecords] = useState([]);
  const hasMore = useRef(true);
  const [requestState, setRequestState] = useState({ loading: false, hasMore: true });

  const [showPurchaseRecordEditModal, setShowPurchaseRecordEditModal] = useState(false);
  const [selectedRecordObj, setSelectedRecordObj] = useState({});

  const [requestCount, setRequestCount] = useState(0);
  const reqParams = useRef({ page: 0 });

  const responseDataHandle = (respObj) => {
    // respObj: 请求结果
    console.log(respObj);
    hasMore.current = false;
    setRequestState({ loading: false });
    if (!respObj || respObj.code !== 1000) {
      message.error('Error Netword...');
      message.error('网络错误...');
      return;
    }

    if (respObj.next) {
      hasMore.current = true;
      setRequestState({ loading: false });
    }
    if (Array.isArray(respObj.results)) {
      setRecords(records.concat(respObj.results));
    } else {
      console.log(respObj.results);
    }
    setShowPurchaseRecordEditModal(false);  // 关闭购买记录编辑框
  }

  const queryMoreRecords = () => {
    // 加载更多的购物单列表
    if (hasMore.current) {
      reqParams.current = { page: reqParams.current.page + 1, ...queryFactor };
      setRequestCount(requestCount + 1);
      console.log(requestCount);
      setRequestState({ loading: true });
    } else {
      console.log('No More...');
    }
  }

  useEffect(() => {
    // 加载执行一次查询
    reloadRecords();
  }, [queryFactor]);

  const selectedRecordHandle = (currentRecordObj) => {
    // 选中销售记录操作
    setShowPurchaseRecordEditModal(true);
    setSelectedRecordObj(currentRecordObj);
  };

  const reloadRecords = () => {
    // 重新读取顾客购买记录列表, 读取成功后关闭编辑框
    setRecords([]);
    hasMore.current = true;
    setRequestState({ loading: false });
    reqParams.current.page = 0;
    queryMoreRecords();
  }

  return (
    <>
      <div className="purchase-records-infinite-container">
        <InfiniteScroll
          initialLoad={false}
          pageStart={0}
          loadMore={queryMoreRecords}
          hasMore={!requestState.loading && hasMore.current}
          useWindow={false}
        >
          <p>订单列表</p>
          <List
            itemLayout="vertical"
            dataSource={records}
            renderItem={item => (
              <List.Item
                extra={<ImageZoom alt="Image" width="64px" src={item.image_cos_name1} />}
                actions={[<Button onClick={() => selectedRecordHandle(item)}
                  type="link">详情</Button>]}>
                <List.Item.Meta
                  title={`商品: ${item.product_title}; 时间: ${item.add_time};  销售平台: ${salePlatforms[item.sale_platform]}; 订单状态: ${purchaseStates[item.order_state]};`}
                  description={`说明: ${item.explains};`}
                />
              </List.Item>
            )}
          ></List>
          <GetRecordsRequest className="loading-container"
            reqParams={reqParams.current}
            requestCount={requestCount}
            responseDataHandle={responseDataHandle}></GetRecordsRequest>
        </InfiniteScroll>
      </div>
      <PurchaseRccordEdit
        purchaseRecordEditModalState={showPurchaseRecordEditModal}
        setPurchaseRecordEditModalState={setShowPurchaseRecordEditModal}
        recordObj={selectedRecordObj}
        reloadRecords={reloadRecords}
      />
    </>
  )
}

PurchaseRecords.defaultProps = {
  queryFactor: {}
}

export default PurchaseRecords;
