import React from 'react';
import { Modal, Button } from 'antd';

// 选择顾客组件

import CustomerList from '../customer_list';

const SelectCustomerModal = ({ modalState, setModalState, selectCustomerHandle, clickCreatedCustomerHandle }) => {

  return (
    <>
      <Modal
        visible={modalState}
        title="选择顾客"
        footer={[
          <Button key="back" onClick={() => { setModalState(false); }}>取消</Button>
        ]}
      // footer={null}
      ><CustomerList
        selectCustomerHandle={selectCustomerHandle}
        clickCreatedCustomerHandle={clickCreatedCustomerHandle}></CustomerList></Modal>
    </>
  )
}

SelectCustomerModal.defaultProps = {
  modalState: false,
  setModalState: (modalState) => { console.log(modalState) },
  selectCustomerHandle: (customerObj) => { console.log(customerObj); },
  clickCreatedCustomerHandle: (createdCustomerResponseObj) => { console.log(createdCustomerResponseObj) },
}

export default SelectCustomerModal;
