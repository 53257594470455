import React, { useState, useEffect, useRef } from 'react';
import {
  Form,
  Input,
  Button,
  Modal,
  Tooltip,
  Space,
  Row, Col
} from 'antd';
import { EditOutlined, ExclamationCircleOutlined, CloseOutlined } from '@ant-design/icons';

// 编辑订单
import UploadMultipleImages from '../upload_files/uploadFilesHandle';
import SelectCustomerModal from '../select_customer';
import { GetCustomerInfo, GetCustomerWxInfo } from '../customer_list/customer_requests';
import { CreateUpdateRequest, DeletePurchaseRecord } from './records_request_handle';
// import Zmage from 'react-zmage';
import {
  noDataRecordObj,
  recordFormLabel,
  disabledKeys,
  imagesKeys,
  formKeySelectComponentsObj
} from './records_columns_config';
import customConfig from '../../custom_config';

// 创建顾客 Form
const formCommonStyle = customConfig.formCommonStyle;
const styleLayoutForm = formCommonStyle.layoutForm;
const styleFormItemLayout = formCommonStyle.formItemLayout;
const styleTailFormItemLayout = formCommonStyle.tailFormItemLayout;

const { confirm } = Modal;

const PurchaseRccordEdit = ({ purchaseRecordEditModalState, setPurchaseRecordEditModalState, recordObj, reloadRecords }) => {
  const [form] = Form.useForm();
  const [deletePurchaseRecordId, setDeletePurchaseRecordId] = useState(0);
  const [deltetPurchaseRecordCount, setDeltetPurchaseRecordCount] = useState(0);

  const [customerName, setCustomerName] = useState('');
  const [customerAvatarUrl, setCustomerAvatarUrl] = useState('');
  const customerId = useRef(0);
  const [changeSelectCustomerModalState, setChangeSelectCustomerModalState] = useState(false);

  const uploadedImagesCosNameObj = useRef({});
  const [oldImageUrlsObj, setOldImageUrlsObj] = useState({});

  const recordUpdateObj = useRef({});
  const [updateRecordCounts, setUpdateRecordCounts] = useState(0);

  useEffect(() => {
    // 更新customerId formData
    console.log(recordObj);
    setCustomerName('');
    setCustomerAvatarUrl('');
    customerId.current = recordObj.customer_id;
    form.setFieldsValue(recordObj);

    // 更新 oldImageUrlsObj
    const oldUrlObj = {};
    for (let index = 0; index < imagesKeys.length; index++) {
      const element = imagesKeys[index];
      oldUrlObj[element] = recordObj[element];
    }
    setOldImageUrlsObj(oldUrlObj);
  }, [recordObj]);

  const changeImageHandle = (uploadedImagesObj) => {
    // 上传或删除图片后的操作 uploadedImagesObj.current = {"image_cos_name1": "", ...}
    const obj = {};
    for (const key in uploadedImagesObj) {
      if (uploadedImagesObj.hasOwnProperty(key)) {
        obj[key] = uploadedImagesObj[key].image_cos_name;
      }
    }
    console.log(obj);
    uploadedImagesCosNameObj.current = obj;
  }

  const selectCustomerHandle = (customerObj) => {
    // 选择顾客后操作
    // customerOrInfo: 0-customer, 1-wx
    const customerOrInfo = Object.keys(customerObj).includes('customer_id') ? 1 : 0;
    let customerName = customerObj.name;
    let currentCustomerId = customerObj.id;
    let customerAvatar = customerObj.avatar
    if (customerOrInfo === 1) {
      customerName = customerObj.wx_nickname;
      currentCustomerId = customerObj.customer_id;
      customerAvatar = customerObj.wx_avatar
    }
    setCustomerName(customerName);
    form.setFieldsValue({ customer_id: currentCustomerId });
    setChangeSelectCustomerModalState(false);
    setCustomerAvatarUrl(customerAvatar);
  };

  const clearCurrentCustomer = () => {
    // 清除当前用户
    confirm({
      title: `确定删除用户${customerName}吗?`,
      icon: <ExclamationCircleOutlined />,
      content: '如果误删, 可以点击取消!',
      onOk() {
        setCustomerName('');
        form.setFieldsValue({ customer_id: 0 });
        setCustomerAvatarUrl('');
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  const onFinish = values => {
    console.log(uploadedImagesCosNameObj.current);
    recordUpdateObj.current = { ...values, ...uploadedImagesCosNameObj.current };
    // 发送更新顾客购买记录请求
    console.log(recordUpdateObj.current);
    setUpdateRecordCounts(updateRecordCounts + 1);
    reloadRecords(); // 重新读取顾客购买记录列表, 读取成功后关闭编辑框
  };

  const respCustomerObj = (responseObj) => {
    // 获取顾客详情后操作
    // console.log(responseObj);
    setCustomerName('');
    setCustomerAvatarUrl('');
    setTimeout(() => {
      if (Object.keys(responseObj).includes('results')
        && Array.isArray(responseObj.results)
        && responseObj.results.length > 0) {
        setCustomerName(responseObj.results[0].name);
        setCustomerAvatarUrl(responseObj.results[0].avatar);
      }
    }, 1000);
  }

  const respCustomerWxObj = (respWxObj) => {
    // 获取微信详情后操作
    setTimeout(() => {
      if (Object.keys(respWxObj).includes('results')
        && Array.isArray(respWxObj.results)
        && respWxObj.results.length > 0) {
        const wxNickname = customerName.length > 1 ? customerName.length : respWxObj.results[0].wx_nickname;
        const wxAvatar = customerAvatarUrl.length > 10 ? customerAvatarUrl : respWxObj.results[0].wx_avatar;
        setCustomerName(wxNickname);
        setCustomerAvatarUrl(wxAvatar);
      }
    }, 2000);
  }

  const deleteCurrentRecord = () => {
    // 删除当前的订单
    console.log('Error');
    confirm({
      title: '确定要删除当前订单吗?',
      icon: <ExclamationCircleOutlined />,
      content: '订单删除后将无法恢复...',
      onOk() {
        console.log(recordObj);
        setDeletePurchaseRecordId(recordObj.id);
        setDeltetPurchaseRecordCount(deltetPurchaseRecordCount + 1);
        reloadRecords(); // 重新读取顾客购买记录列表, 读取成功后关闭编辑框
      },
      onCancel() { },
    });
  }

  return (
    // <p>Error...</p>
    <Modal
      getContainer={false}
      title="订单详情"
      visible={purchaseRecordEditModalState}
      footer={null}
      closable={false}
    >
      <GetCustomerInfo customerId={customerId.current} responseDataHandle={respCustomerObj} />
      <Row gutter={16}>
        <Col span={12}>
          <h3>昵称: {customerName}</h3>
        </Col>
        <Col span={8}>
          {customerAvatarUrl.length > 10
            ? (<img height="64px" alt="Avatar" src={customerAvatarUrl}></img>)
            : null}
        </Col>
        <Col span={4}>
          <Space>
            <Tooltip title="删除当前顾客">
              <Button onClick={clearCurrentCustomer} shape="circle" icon={<CloseOutlined />} />
            </Tooltip>
            <Tooltip title="选择或新建顾客">
              <Button type="primary" onClick={() => { setChangeSelectCustomerModalState(true) }} shape="circle" icon={<EditOutlined />} />
            </Tooltip>
          </Space>
        </Col>
      </Row>
      <br></br>
      <GetCustomerWxInfo customerId={customerId.current} responseDataHandle={respCustomerWxObj} />
      <br></br><hr></hr><br></br>
      <UploadMultipleImages
        multipleObjKeys={imagesKeys}
        oldImageUrlsObj={oldImageUrlsObj}
        uploadedHandle={changeImageHandle}></UploadMultipleImages>
      <Form
        {...styleLayoutForm}
        form={form}
        name="purchaserecord"
        layout="horizontal"
        initialValues={noDataRecordObj}
        onFinish={onFinish}
        size="large"
      >
        {Object.keys(noDataRecordObj).map((currentKey, index) => {
          if (imagesKeys.includes(currentKey)) {
            return null;
          }
          return (
            <Form.Item {...styleFormItemLayout} label={recordFormLabel[currentKey]} name={currentKey} key={index.toString()}>
              {Object.keys(formKeySelectComponentsObj).includes(currentKey)
                ? formKeySelectComponentsObj[currentKey]
                : <Input disabled={disabledKeys.includes(currentKey)} />}
            </Form.Item>
          )
        })}
        <Form.Item {...styleTailFormItemLayout}>
          <Space>
            <Button type="primary" htmlType="submit">提交</Button>
            <Button htmlType="button" onClick={() => setPurchaseRecordEditModalState(false)}>取消</Button>
            <Button htmlType="button" onClick={deleteCurrentRecord}>删除订单</Button>
          </Space>
        </Form.Item>
      </Form>

      <CreateUpdateRequest method="put" recordId={recordObj.id} reqObject={recordUpdateObj.current} requestCount={updateRecordCounts}></CreateUpdateRequest>

      <DeletePurchaseRecord
        recordId={deletePurchaseRecordId}
        setRecordId={setDeletePurchaseRecordId}
        deltetPurchaseRecordCount={deltetPurchaseRecordCount} />

      <SelectCustomerModal
        modalState={changeSelectCustomerModalState}
        setModalState={setChangeSelectCustomerModalState}
        selectCustomerHandle={selectCustomerHandle}
        clickCreatedCustomerHandle={selectCustomerHandle} />
    </Modal>
  )
}

PurchaseRccordEdit.defaultProps = {
  reloadRecords: () => { console.log('Reload pruchase records...'); },
}

export default PurchaseRccordEdit;
