import React, { useRef, useEffect } from 'react';
import { Space } from 'antd';

// import UploadSingleFileHandle from './uploadSingleFileHandle';
// 使用sts 上传文件; 服务端生成临时密钥，客户端直接上传到腾讯云 cos
import StsUploadFile from './sts_upload_file';
// 上传多张图片操作

const UploadMultipleImages = ({ multipleObjKeys, oldImageUrlsObj, uploadedHandle }) => {
  /**
   * 返回 multipleImagesObj.current = {
   *    imageKey1: {id:1, image_cos_name: 'AAA.jpg', image_cos_name_url: 'https://AAA.jpg'},
   *    imageKey2: {id:2, image_cos_name: 'BBB.jpg', image_cos_name_url: 'https://BBB.jpg'},
   *    ......
   * }
   * 
   * oldImageUrlsObj = {image_cos_name1: 'url, ......}
  */
  const multipleImagesObj = useRef({});

  useEffect(() => {
    multipleImagesObj.current = {};
  }, [oldImageUrlsObj])

  const uploadedImageResponseHandle = (currentuploadedImageObj, imageKey) => {
    // 单张图片上传后更新 multipleImagesObj
    console.log(imageKey);
    const deepCopyMultipleObj = { ...multipleImagesObj.current };
    deepCopyMultipleObj[imageKey] = currentuploadedImageObj;
    multipleImagesObj.current = deepCopyMultipleObj;
    uploadedHandle(deepCopyMultipleObj);
  }

  const singleImageUploadedHandle = (uploadedResponseObj, fileKey) => {
    // 单张图片上传后的操作
    const resObjKeys = Object.keys(uploadedResponseObj)
    if (resObjKeys.includes('id') && resObjKeys.includes('image_cos_name')) {
      const currentuploadedImageObj = {
        id: uploadedResponseObj.id,
        image_cos_name: uploadedResponseObj.image_cos_name,
        image_cos_name_url: uploadedResponseObj.image_cos_name_url
      };
      uploadedImageResponseHandle(currentuploadedImageObj, fileKey);
    }
  }
  const imageBoxStyle = {
    'width': '100%',
    'height': 'auto',
    'textAlign': 'center',
    'margin': '0.5rem',
    'border': 'medium double rgb(250,0,255)',
    'padding': '0.5rem',
  };

  return (
    <div style={imageBoxStyle}>
      <Space direction="vertical" size="middle">
        {multipleObjKeys.map((key) => {
          return (
            <StsUploadFile key={key.toString()}
              oldImageUrl={Object.keys(oldImageUrlsObj).includes(key) ? oldImageUrlsObj[key] : ''}
              avatarSize="96px"
              uploadedResponseHandle={singleImageUploadedHandle}
              fileKey={key}
            ></StsUploadFile>
          )
        })}
      </Space>
      <br></br>
    </div>
  )
}

UploadMultipleImages.defaultProps = {
  multipleObjKeys: ['image_cos_name1', 'image_cos_name2', 'image_cos_name3', 'image_cos_name4', 'image_cos_name5'],
  oldImageUrlsObj: {},
  uploadedHandle: (multipleObj) => { console.log(multipleObj); },
}

export default UploadMultipleImages;
