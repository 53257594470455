import React, { useState, useRef } from 'react';
import { Modal, Drawer, Form, Button, Input, DatePicker, Space, Row, Col, Tooltip } from 'antd';
import { SearchOutlined, CloseOutlined } from '@ant-design/icons';

import CustomerList from '../customer_list';
import PurchaseRecords from './purchase_records';
import SelectSalePlatform from '../select_sale_platform';
import customConfig from '../../custom_config';

const { RangePicker } = DatePicker;
// 查询、编辑销售记录

const formFieldsDefaultValues = {
  customername: '',
  add_time: [],
  sale_platform: 0,
};

const formCommonStyle = customConfig.formCommonStyle;
// layoutForm, formItemLayout, tailFormItemLayout
const styleLayoutForm = formCommonStyle.layoutForm;
const styleFormItemLayout = formCommonStyle.formItemLayout;
const styleTailFormItemLayout = formCommonStyle.tailFormItemLayout;

const QueryEditRecords = () => {
  const [queryFactor, setQuerFactor] = useState({});
  const [form] = Form.useForm();
  const [queryDrawerState, setQueryDrawerState] = useState(false)
  const [showSelectCustomerModal, setShowSelectCustomerModal] = useState(false);

  const selectedCustomerObj = useRef({});

  const showQueryDrawer = () => {
    // 显示查询抽屉
    setQueryDrawerState(true);
  };

  const onFinish = values => {
    // 表单提交
    console.log(values);
    const rangeValue = values['add_time'];
    const rangePicker = (Array.isArray(rangeValue) && rangeValue.length > 1)
      ? [rangeValue[0].format('YYYY-MM-DD'), rangeValue[1].format('YYYY-MM-DD')]
      : null;
    const editObj = {};
    const customer_id = Object.keys(selectedCustomerObj.current).includes('customer_id')
      ? selectedCustomerObj.current.customer_id
      : Object.keys(selectedCustomerObj.current).includes('id')
        ? selectedCustomerObj.current.id : 0;
    if (customer_id > 0) {
      // 添加顾客id查询
      editObj.customer_id = customer_id;
    }
    if (values.sale_platform > 0) {
      editObj.sale_platform = values.sale_platform;
    }
    if (rangePicker) {
      editObj['add_time_start'] = rangePicker[0];
      editObj['add_time_end'] = rangePicker[1];
    }
    console.log(editObj);
    setQuerFactor(editObj);
    setQueryDrawerState(false);
  };

  const selectCustomerHandle = (customerObj) => {
    // 选中顾客后操作
    selectedCustomerObj.current = customerObj;
    const objKeys = Object.keys(customerObj);
    const nickName =
      objKeys.includes('name')
        ? customerObj.name
        : objKeys.includes('wx_nickname') ? customerObj.wx_nickname : '';
    form.setFieldsValue({
      customername: nickName,
    });
    setShowSelectCustomerModal(false);
  }

  const clearSelectedCustomer = () => {
    // 清除选中的顾客
    form.setFieldsValue({
      customername: '',
    });
    selectedCustomerObj.current = {};
  }

  return (
    <>
      <Button type="primary" onClick={showQueryDrawer}>
        <SearchOutlined /> Search
        </Button>
      <Drawer
        title="销售记录查询"
        width="100%"
        height="400px"
        placement="top"
        onClose={() => setQueryDrawerState(false)}
        visible={queryDrawerState}
        bodyStyle={{ paddingBottom: 80 }}
        footer={null}
      >
        <Form {...styleLayoutForm}
          layout="horizontal"
          form={form}
          name="query-records"
          onFinish={onFinish}
          initialValues={formFieldsDefaultValues}>
          <Form.Item {...styleFormItemLayout} label="顾客">
            <Row gutter={8}>
              <Col span={18}>
                <Form.Item
                  name="customername"
                  noStyle
                >
                  <Input disabled={true} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Space>
                  <Tooltip title="清除选中顾客">
                    <Button type="dashed" onClick={clearSelectedCustomer} shape="circle" icon={<CloseOutlined />} />
                  </Tooltip>
                  <Tooltip title="选择或新建顾客">
                    <Button type="primary" onClick={() => { setShowSelectCustomerModal(true) }} shape="circle" icon={<SearchOutlined />} />
                  </Tooltip>
                </Space>
              </Col>
            </Row>
          </Form.Item>

          <Form.Item {...styleFormItemLayout} label="日期" name="add_time" style={{ marginBottom: 0 }}>
            <RangePicker />
          </Form.Item>

          <Form.Item {...styleFormItemLayout} label="销售平台" name="sale_platform">
            <SelectSalePlatform />
          </Form.Item>

          <Form.Item {...styleTailFormItemLayout}>
            <Space>
              <Button onClick={() => setQueryDrawerState(false)}>
                取消
              </Button>
              <Button type="primary" htmlType="submit">
                查询
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Drawer>
      <PurchaseRecords queryFactor={queryFactor} />

      <Modal
        visible={showSelectCustomerModal}
        title="选择顾客"
        footer={[
          <Button key="back" onClick={() => { setShowSelectCustomerModal(false); }}>取消</Button>
        ]}
      ><CustomerList selectCustomerHandle={selectCustomerHandle} /></Modal>
    </>
  )
};

export default QueryEditRecords;
