import React from 'react';
import AxiosRequest from '../apis';
import { message } from 'antd';
// 顾客购买记录的apis请求

const GetRecordsRequest = ({ reqParams, requestCount, responseDataHandle }) => {
  // get 请求 获取顾客订单订单列表
  const method = 'get';
  const reqObject = {};
  const api = 'records-admin/v1/purchaserecord';

  return (
    <AxiosRequest
      responseDataHandle={responseDataHandle} api={api} method={method}
      reqParams={reqParams} reqObject={reqObject} requestCount={requestCount}
    ></AxiosRequest>
  )
};

GetRecordsRequest.defaultProps = {
  reqParams: {page: 1},
  requestCount: 0,
  responseDataHandle: (respObj) => {
    console.log(respObj);
  }
};

const CreateUpdateRequest = ({ method, recordId, reqObject, requestCount, responseDataHandle }) => {
  // post put 请求
  const reqParams = {};
  let api = method === 'post' ? 'records-admin/v1/purchaserecord' : `records-admin/v1/purchaserecord/${recordId}`;

  return (
    <AxiosRequest
      responseDataHandle={responseDataHandle} api={api} method={method}
      reqParams={reqParams} reqObject={reqObject} requestCount={requestCount}
    ></AxiosRequest>
  )
};

CreateUpdateRequest.defaultProps = {
  method: 'post',
  recordId: 0,
  reqObject: {},
  requestCount: 0,
  responseDataHandle: (respObj) => {
    console.log(respObj);
    if (Object.keys(respObj).includes('code') && respObj.code === 1000) {
      message.success('Success');
      message.success('更新顾客购买记录成功');
    } else {
      message.error('Error');
      message.error('Error: 更新失败');
    }
  },
};

const DeletePurchaseRecord = ({ recordId, setRecordId, deltetPurchaseRecordCount }) => {
  const reqParams = {};
  const reqObject = {};
  const api = `records-admin/v1/purchaserecord/${recordId}`;
  const method = 'delete';

  const responseDataHandle = (respObj) => {
    // respObj 是请求结果
    console.log('Deleted', respObj);
    setRecordId(0);
  }

  return (
    <AxiosRequest
      responseDataHandle={responseDataHandle} api={api} method={method}
      reqParams={reqParams} reqObject={reqObject} requestCount={deltetPurchaseRecordCount}
    ></AxiosRequest>
  )
}

DeletePurchaseRecord.defaultProps = {
  recordId: 0,
  setRecordId: (deleteId) => { console.log(deleteId); },
  deltetPurchaseRecordCount: 0,
}

export { GetRecordsRequest, CreateUpdateRequest, DeletePurchaseRecord };
