import React from 'react';

import SiteLayout from '../components/site_layout';
import QueryEditRecords from '../components/query_edit_records';

import SEO from "../components/seo";

const QueryEditRecordsPage = () => (
  <SiteLayout>
    <SEO title='Records'></SEO>
    <QueryEditRecords></QueryEditRecords>
  </SiteLayout>
);

export default QueryEditRecordsPage;
