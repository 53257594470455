import React from 'react';
import { CustomSelect } from '../base_components/select_component';
import SelectSalePlatform from '../select_sale_platform';

// records 列和配置等

const noDataRecordObj = {
  "id": 0, "customer_id": 0, "product_title": "",
  "diopter_r": "", "diopter_l": "", "pupillary_distance": "", "receive_address": "",
  "products_quantity": 0, "purchase_amount": 0, "sale_platform": 0,
  "order_state": 0, "add_time": "", "update_time": "", "explains": "", "image_cos_name1": "",
  "image_cos_name2": "", "image_cos_name3": "", "image_cos_name4": "", "image_cos_name5": ""
};

const recordFormLabel = {
  "id": "订单ID", "customer_id": "顾客ID", "product_title": "商品名称",
  "diopter_r": "光度R", "diopter_l": "光度L", "pupillary_distance": "瞳距PD", "receive_address": "收货地址",
  "products_quantity": "商品数量", "purchase_amount": "总金额", "sale_platform": "销售平台",
  "order_state": "订单状态", "add_time": "创建时间", "update_time": "更新时间", "explains": "说明", "image_cos_name1": "图片1",
  "image_cos_name2": "图片2", "image_cos_name3": "图片3", "image_cos_name4": "图片4", "image_cos_name5": "图片5"
}

const disabledKeys = ["id", "customer_id", "add_time", "update_time"];

const imagesKeys = ["image_cos_name1", "image_cos_name2", "image_cos_name3", "image_cos_name4", "image_cos_name5"]

// 0:订单交易完成, 1:定制中, 2:已发货
const OrderState = (<CustomSelect
  options={[{ value: 0, label: '订单交易完成' }, { value: 1, label: '定制中' }, { value: 2, label: '已发货' },]} />);

const formKeySelectComponentsObj = {
  sale_platform: <SelectSalePlatform />,
  order_state: OrderState,
}

export {
  noDataRecordObj,
  recordFormLabel,
  disabledKeys,
  imagesKeys,
  formKeySelectComponentsObj
};
